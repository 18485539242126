<template>
	<div class="main__container main__padding column__center">
		<div class="imprint-box  column__center">
			<div class="imprint-box--small mb-50">
				<h2>Kontakt</h2>
				<div class="header-3">
					Schreiben Sie uns bitte für alle Ihre Anfragen eine E-Mail an:
				</div>
				<div class="mb-50">
					<p>
						info@ausbildungschance-owl.de
					</p>
				</div>
				<general-input :header="'Name'" v-model="mail.name" class="mb-20" />
				<general-input :header="'E-Mail'" v-model="mail.email" class="mb-20" />
				<general-input
					:header="'Betreff'"
					v-model="mail.subject"
					class="mb-20"
				/>
				<general-textarea
					:header="'Nachricht'"
					v-model="mail.body"
					class="mb-30"
				/>
				<div class="button button--big" @click="sendContact">
					<p class="text__white">Nachricht abschicken</p>
				</div>
				<div class="mt-10" v-if="feedbackStatus == 'success'">
					<p class="text__primary">
						Ihre Anfrage wurde abgeschickt.
					</p>
				</div>
				<div class="mt-10" v-if="feedbackStatus == 'error'">
					<p class="text__red">
						Es ist ein Fehler aufgetreten, bitte versuchen sie es später erneut.
					</p>
				</div>
			</div>
			<Footer class="w-100" />
		</div>
	</div>
</template>

<script>
import Footer from "../../components/Footer.vue";
import GeneralInput from "../../helper/GeneralInput.vue";
import GeneralTextarea from "../../helper/GeneralTextarea.vue";

import Axios from "axios";

export default {
	components: { Footer, GeneralInput, GeneralTextarea },
	name: "Contact",
	data() {
		return {
			mail: {},
			feedbackStatus: null,
		};
	},
	methods: {
		sendContact() {
			Axios.post(window.baselink + "contact", this.mail)
				.then((response) => {
					if (response.status == 200) {
						this.feedbackStatus = "success";
						this.mail = {};
						return response.data;
					}
				})
				.catch((error) => {
					this.feedbackStatus = "error";
					return error.response.data;
				});
		},
	},
	created() {},
	computed: {},
};
</script>
