<template>
    <div class="main__container main__padding">
        <h2>Datenschutz&shy;erkl&auml;rung</h2>
        <div class="imprint-box">
            <div class="header-1">1. Datenschutz auf einen Blick</div>
            <div class="header-3">Allgemeine Hinweise</div>
            <div>
                <p>
                    Die folgenden Hinweise geben einen einfachen Überblick
                    darüber, was mit Ihren personenbezogenen Daten passiert,
                    wenn Sie diese Website besuchen. Personenbezogene Daten sind
                    alle Daten, mit denen Sie persönlich identifiziert werden
                    können. Ausführliche Informationen zum Thema Datenschutz
                    entnehmen Sie unserer unter diesem Text aufgeführten
                    Datenschutzerklärung.
                </p>
                <br />
                <p>
                    Ferner gelten die Datenschutzhinweise der IHK Ostwestfalen
                    zu Bielefeld:<br />
                    <a
                        href="https://www.ostwestfalen.ihk.de/footer-navigation-datenschutz/datenschutz"
                    >
                        https://www.ostwestfalen.ihk.de/footer-navigation-datenschutz/datenschutz</a
                    >
                </p>
            </div>
            <br />
            <div class="header-4">
                Wer ist verantwortlich für die Datenerfassung auf dieser
                Website?
            </div>
            <div>
                Die Datenverarbeitung auf dieser Website erfolgt durch den
                Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
                „Hinweis zur Verantwortlichen Stelle“ in dieser
                Datenschutzerklärung entnehmen.
            </div>
            <br />
            <div class="header-4">Wie erfassen wir Ihre Daten?</div>
            <div>
                Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
                mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie
                in ein Kontaktformular eingeben. Andere Daten werden automatisch
                oder nach Ihrer Einwilligung beim Besuch der Website durch
                unsere IT-Systeme erfasst. Das sind vor allem technische Daten
                (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des
                Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch,
                sobald Sie diese Website betreten.
            </div>
            <br />
            <div class="header-4">Welche Daten erfassen wir?</div>
            <div>
                Schülerregistrierung: E-Mail Adresse und selbst gewähltes
                Passwort
                <br />
                Unternehmensregistrierung (Pflichteingaben): Name des
                Unternehmens, Adresse, Webseite, Name des Ansprechpartners,
                Funktion, E-Mail-Adresse, Telefonnummer, Zuordnung <br />
                Profildaten der teilnehmenden Unternehmen (optional):
                Ausbildungsberufe, Gesprächspartner, Terminmöglichkeiten, Links
                zu Youtube oder Vimeo (Unternehmensfilme), Profilbilder,
                Unternehmensfotos und Logo, Verlinkung zu Social Media-Kanälen,
                Freitextfelder zur Unternehmensbeschreibung
            </div>
            <br />
            <div class="header-4">Wofür nutzen wir Ihre Daten?</div>
            <div>
                App „Ausbildungschance OWL“ und Website
                www.ausbildungschance-owl.de: Bewerber können Termine per
                Telefon oder Videokonferenz/Chat mit Ausbildungsbetrieben
                vereinbaren. Ein Teil der Daten wird erhoben, um eine
                fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
                Daten können zur Analyse Ihres Nutzerverhaltens verwendet
                werden.

                <br />

                Die Internetseite ausbildungschance-owl.de und die App
                “Ausbildungschance OWL” stellt Daten ausschließlich zur
                Anbahnung von Ausbildungsverhältnissen und Praktika bereit.
                Daher werden insbesondere die Bewerberdaten (ausschließlich
                E-Mail Adressen) mit höchster Sorgfalt behandelt. Der Betreiber
                leistet keine weitergehende Unterstützungsleistung für den
                Abschluss eines Ausbildungsvertrages/einer
                Praktikumsvereinbarung. Die E-Mail Adresse des Bewerbers wird in
                keinem Fall weitergeleitet, sondern lediglich und ausschließlich
                dafür genutzt, um die für die Gespräche erforderlichen
                Kontaktdaten an den Bewerber selbst zu senden.
            </div>
            <br />
            <div class="header-4">
                Welche Rechte haben Sie bezüglich Ihrer Daten?
            </div>
            <div>
                Sie haben jederzeit das Recht, unentgeltlich Auskunft über
                Herkunft, Empfänger und Zweck Ihrer gespeicherten
                personenbezogenen Daten zu erhalten. Sie haben außerdem ein
                Recht, die Berichtigung oder Löschung dieser Daten zu verlangen.
                Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
                können Sie diese Einwilligung jederzeit für die Zukunft
                widerrufen. Außerdem haben Sie das Recht, unter bestimmten
                Umständen die Einschränkung der Verarbeitung Ihrer
                personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen
                ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
                Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie
                sich jederzeit an uns wenden.
            </div>
            <br />
            <div class="header-1">
                2. Hosting und Content Delivery Networks (CDN)
            </div>
            <div class="header-3">Externes Hosting</div>
            <div>
                Diese Website wird bei einem externen Dienstleister gehostet
                (Hoster). Die personenbezogenen Daten, die auf dieser Website
                erfasst werden, werden auf den Servern des Hosters gespeichert.
                Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta
                und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen,
                Websitezugriffe und sonstige Daten, die über eine Website
                generiert werden, handeln.
            </div>
            <div>
                Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
                gegenüber unseren potenziellen und bestehenden Kunden (Art. 6
                Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen
                und effizienten Bereitstellung unseres Online-Angebots durch
                einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
            </div>
            <div>
                Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies
                zur Erfüllung seiner Leistungspflichten erforderlich ist und
                unsere Weisungen in Bezug auf diese Daten befolgen.
            </div>
            <br />
            <div>Wir setzen folgenden Hoster ein:</div>
            <div>
                spieker marketing &amp; cloud agency<br />
                spieker products GmbH<br />
                Sunderhooker Weg 3<br />
                48599 Gronau-Epe
            </div>
            <br />
            <div class="header-4">
                Abschluss eines Vertrages über Auftragsverarbeitung
            </div>
            <div>
                Um die datenschutzkonforme Verarbeitung zu gew&auml;hrleisten,
                haben wir einen Vertrag &uuml;ber Auftragsverarbeitung mit
                unserem Hoster geschlossen.
            </div>
            <br />
            <div class="header-1">
                3. Allgemeine Hinweise und Pflichtinformationen
            </div>
            <div class="header-3">Datenschutz</div>
            <div>
                Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
                Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
                vertraulich und entsprechend den gesetzlichen
                Datenschutzvorschriften sowie dieser Datenschutzerklärung.
            </div>
            <div>
                Wenn Sie diese Website benutzen, werden verschiedene
                personenbezogene Daten erhoben. Personenbezogene Daten sind
                Daten, mit denen Sie persönlich identifiziert werden können. Die
                vorliegende Datenschutzerklärung erläutert, welche Daten wir
                erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu
                welchem Zweck das geschieht.
            </div>
            <br />
            <div>
                Wir weisen darauf hin, dass die Datenübertragung im Internet (z.
                B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
                kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
                Dritte ist nicht möglich.
            </div>
            <div class="header-3">Hinweis zur verantwortlichen Stelle</div>
            <div>
                Die verantwortliche Stelle für die Datenverarbeitung auf dieser
                Website ist:
            </div>
            <div>
                Industrie- und Handelskammer Ostwestfalen zu Bielefeld,
                <br />
                Elsa-Brändström-Str. 1-3<br />
                33602 Bielefeld <br />
            </div>

            <div>
                Telefon: +49 (0)521 554-0<br />
                E-Mail: info@ostwestfalen.ihk.de
            </div>
            <br />
            <div>
                Verantwortliche Stelle ist die natürliche oder juristische
                Person, die allein oder gemeinsam mit anderen über die Zwecke
                und Mittel der Verarbeitung von personenbezogenen Daten (z. B.
                Namen, E-Mail-Adressen o. Ä.) entscheidet.
            </div>
            <br />
            <div class="header-3">Speicherdauer</div>
            <div>
                Unternehmen können ihre Registrierungs- und Profildaten
                jederzeit eigenständig löschen. Wenn Sie ein berechtigtes
                Löschersuchen geltend machen oder eine Einwilligung zur
                Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern
                wir keine anderen rechtlich zulässigen Gründe für die
                Speicherung Ihrer personenbezogenen Daten haben (z. B. steuer-
                oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten
                Fall erfolgt die Löschung nach Fortfall dieser Gründe.
            </div>
            <br />
            <div>
                Die Daten der Bewerber und Bewerberinnen werden sechs Monate
                nach letztmaligem Login gelöscht.
            </div>
            <div class="header-3">
                Widerruf Ihrer Einwilligung zur Datenverarbeitung
            </div>
            <div>
                Viele Datenverarbeitungsvorgänge sind nur mit Ihrer
                ausdrücklichen Einwilligung möglich. Sie können eine bereits
                erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit
                der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom
                Widerruf unberührt. Widerspruchsrecht gegen die Datenerhebung in
                besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)
            </div>
            <br />
            <div>
                WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT.
                E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS
                GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN
                DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH
                EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN
                GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN
                EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER
                DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR
                IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN,
                ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE
                VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND
                FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER
                GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN
                (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO). WERDEN IHRE
                PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
                BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN
                DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM
                ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS
                PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG
                STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN
                DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG
                VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO)
            </div>
            <br />
            <div class="header-3">
                Beschwerderecht bei der zuständigen Aufsichtsbehörde
            </div>
            <div>
                Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
                Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
                Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres
                Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das
                Beschwerderecht besteht unbeschadet anderweitiger
                verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
            </div>
            <br />
            <div class="header-3">
                Recht auf Datenübertragbarkeit
            </div>
            <div>
                Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
                Einwilligung oder in Erfüllung eines Vertrags automatisiert
                verarbeiten, an sich oder an einen Dritten in einem gängigen,
                maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die
                direkte Übertragung der Daten an einen anderen Verantwortlichen
                verlangen, erfolgt dies nur, soweit es technisch machbar ist.
            </div>
            <br />
            <div class="header-3">SSL- bzw. TLS-Verschlüsselung</div>
            <div>
                Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
                Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
                oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine
                SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung
                erkennen Sie daran, dass die Adresszeile des Browsers von
                „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in
                Ihrer Browserzeile.
            </div>
            <div>
                Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
                Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
                werden. Auskunft, Löschung und Berichtigung
            </div>
            <div>
                Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
                jederzeit das Recht auf unentgeltliche Auskunft über Ihre
                gespeicherten personenbezogenen Daten, deren Herkunft und
                Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht
                auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu
                weiteren Fragen zum Thema personenbezogene Daten können Sie sich
                jederzeit an uns wenden.
            </div>
            <br />
            <div class="header-3">
                Recht auf Einschränkung der Verarbeitung
            </div>
            <div>
                Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
                personenbezogenen Daten zu verlangen. Hierzu können Sie sich
                jederzeit an uns wenden. Das Recht auf Einschränkung der
                Verarbeitung besteht in folgenden Fällen:
            </div>
            <ul>
                <li>
                    Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                    personenbezogenen Daten bestreiten, benötigen wir in der
                    Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung
                    haben Sie das Recht, die Einschränkung der Verarbeitung
                    Ihrer personenbezogenen Daten zu verlangen.
                </li>
                <li>
                    Wenn die Verarbeitung Ihrer personenbezogenen Daten
                    unrechtmäßig geschah/geschieht, können Sie statt der
                    Löschung die Einschränkung der Datenverarbeitung verlangen.
                </li>
                <li>
                    Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen,
                    Sie sie jedoch zur Ausübung, Verteidigung oder
                    Geltendmachung von Rechtsansprüchen benötigen, haben Sie das
                    Recht, statt der Löschung die Einschränkung der Verarbeitung
                    Ihrer personenbezogenen Daten zu verlangen.
                </li>
                <li>
                    Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO
                    eingelegt haben, muss eine Abwägung zwischen Ihren und
                    unseren Interessen vorgenommen werden. Solange noch nicht
                    feststeht, wessen Interessen überwiegen, haben Sie das
                    Recht, die Einschränkung der Verarbeitung Ihrer
                    personenbezogenen Daten zu verlangen.
                </li>
            </ul>
            <div>
                Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
                eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
                abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
                Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz
                der Rechte einer anderen natürlichen oder juristischen Person
                oder aus Gründen eines wichtigen öffentlichen Interesses der
                Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
            </div>
            <br />
            <div class="header-1">4. Datenerfassung auf dieser Website</div>
            <div class="header-3">Cookies</div>
            <div>
                Unsere Internetseiten verwenden so genannte „Cookies“. Cookies
                sind kleine Textdateien und richten auf Ihrem Endgerät keinen
                Schaden an. Sie werden entweder vorübergehend für die Dauer
                einer Sitzung (Session-Cookies) oder dauerhaft (permanente
                Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies werden
                nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies
                bleiben auf Ihrem Endgerät gespeichert, bis Sie diese selbst
                löschen oder eine automatische Löschung durch Ihren Webbrowser
                erfolgt. Teilweise können auch Cookies von Drittunternehmen auf
                Ihrem Endgerät gespeichert werden, wenn Sie unsere Seite
                betreten (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen
                die Nutzung bestimmter Dienstleistungen des Drittunternehmens
                (z. B. Cookies zur Abwicklung von Zahlungsdienstleistungen).
                Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
                technisch notwendig, da bestimmte Websitefunktionen ohne diese
                nicht funktionieren würden (z. B. die Warenkorbfunktion oder die
                Anzeige von Videos). Andere Cookies dienen dazu, das
                Nutzerverhalten auszuwerten oder Werbung anzuzeigen. Cookies,
                die zur Durchführung des elektronischen Kommunikationsvorgangs
                (notwendige Cookies) oder zur Bereitstellung bestimmter, von
                Ihnen erwünschter Funktionen (funktionale Cookies, z. B. für die
                Warenkorbfunktion) oder zur Optimierung der Website (z. B.
                Cookies zur Messung des Webpublikums) erforderlich sind, werden
                auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern
                keine andere Rechtsgrundlage angegeben wird. Der
                Websitebetreiber hat ein berechtigtes Interesse an der
                Speicherung von Cookies zur technisch fehlerfreien und
                optimierten Bereitstellung seiner Dienste. Sofern eine
                Einwilligung zur Speicherung von Cookies abgefragt wurde,
                erfolgt die Speicherung der betreffenden Cookies ausschließlich
                auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO);
                die Einwilligung ist jederzeit widerrufbar.
            </div>
            <div>
                Sie können Ihren Browser so einstellen, dass Sie über das Setzen
                von Cookies informiert werden und Cookies nur im Einzelfall
                erlauben, die Annahme von Cookies für bestimmte Fälle oder
                generell ausschließen sowie das automatische Löschen der Cookies
                beim Schließen des Browsers aktivieren. Bei der Deaktivierung
                von Cookies kann die Funktionalität dieser Website eingeschränkt
                sein.
            </div>
            <div>
                Soweit Cookies von Drittunternehmen oder zu Analysezwecken
                eingesetzt werden, werden wir Sie hierüber im Rahmen dieser
                Datenschutzerklärung gesondert informieren und ggf. eine
                Einwilligung abfragen.
            </div>
            <br />
            <div class="header-3">Anfrage per E-Mail, Telefon oder Telefax</div>
            <div>
                Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
                Ihre Anfrage inklusive aller daraus hervorgehenden
                personenbezogenen Daten (Name, Anfrage) zum Zwecke der
                Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet.
                Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
            </div>
            <div>
                Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
                Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines
                Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
                Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die
                Verarbeitung auf unserem berechtigten Interesse an der
                effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6
                Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1
                lit. a DSGVO) sofern diese abgefragt wurde.
            </div>
            <div>
                Die von Ihnen an uns per Kontaktanfragen übersandten Daten
                verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
                Einwilligung zur Speicherung widerrufen oder der Zweck für die
                Datenspeicherung entfällt (z. B. nach abgeschlossener
                Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen
                – insbesondere gesetzliche Aufbewahrungsfristen – bleiben
                unberührt.
            </div>
            <br />
            <div class="header-1">5. Plugins und Tools</div>
            <div class="header-3">Google Maps</div>
            <div>
                Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die
                Google Ireland Limited („Google“), Gordon House, Barrow Street,
                Dublin 4, Irland. Zur Nutzung der Funktionen von Google Maps ist
                es notwendig, Ihre IP-Adresse zu speichern. Diese Informationen
                werden in der Regel an einen Server von Google in den USA
                übertragen und dort gespeichert. Der Anbieter dieser Seite hat
                keinen Einfluss auf diese Datenübertragung. Wenn Google Maps
                aktiviert ist, kann Google zum Zwecke der einheitlichen
                Darstellung der Schriftarten Google Web Fonts verwenden. Beim
                Aufruf von Google Maps lädt Ihr Browser die benötigten Web Fonts
                in ihren Browsercache, um Texte und Schriftarten korrekt
                anzuzeigen.
            </div>
            <div>
                Die Nutzung von Google Maps erfolgt im Interesse einer
                ansprechenden Darstellung unserer Online-Angebote und an einer
                leichten Auffindbarkeit der von uns auf der Website angegebenen
                Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6
                Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung
                abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf
                Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
                jederzeit widerrufbar.
            </div>
            <div>
                Die Datenübertragung in die USA wird auf die
                Standardvertragsklauseln der EU-Kommission gestützt. Details
                finden Sie hier: <br />
                <a
                    href="https://privacy.google.com/businesses/gdprcontrollerterms/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >https://privacy.google.com/businesses/gdprcontrollerterms/</a
                >
                und
                <a
                    href="https://privacy.google.com/businesses/gdprcontrollerterms/sccs/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >https://privacy.google.com/businesses/gdprcontrollerterms/sccs/.</a
                >
            </div>
            <br />
            <div>
                Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
                Datenschutzerklärung von Google:

                <a
                    href="https://policies.google.com/privacy?hl=de."
                    target="_blank"
                    rel="noopener noreferrer"
                    >https://policies.google.com/privacy?hl=de.</a
                >
            </div>
            <br />
            <div>
                Quelle:
                <a href="https://www.datenschutzerklaerung.de"
                    >https://www.datenschutzerklaerung.de</a
                >
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Footer from "../../components/Footer.vue";
export default {
    components: { Footer },
    name: "Privacy",
    data() {
        return {};
    },
    methods: {},
    created() {},
    computed: {},
};
</script>

<style scoped>
a {
    color: inherit;
}
</style>
